@-webkit-keyframes skeleton-gradient {
  0% {
      background-color: rgba(165, 165, 165, 0.7);
  }

  50% {
      background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
      background-color: rgba(165, 165, 165, 0.7);
  }
}

@keyframes skeleton-gradient {
  0% {
      background-color: rgba(165, 165, 165, 0.7);
  }

  50% {
      background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
      background-color: rgba(165, 165, 165, 0.7);
  }
}

.skeleton-list-item {
  -webkit-animation: skeleton-gradient 1.8s infinite ease-in-out;
  animation: skeleton-gradient 1.8s infinite ease-in-out;
}